<script lang="ts">
  import type { Stack } from '../data/stack'
  export let stack: Stack
</script>

<div class="lists">
  {#each stack as stacklist}
    <ul>
      {#each stacklist as item}
        <li>{item}</li>
      {/each}
    </ul>
  {/each}
</div>

<style lang="scss">
  .lists {
    display: flex;
    margin-top: 2rem;
    ul {
      padding: 0 2rem;
      list-style-type: none;
      li {
        margin: 1rem;
        padding: 0.5rem 2rem;
        line-height: 2rem;
        text-align: center;
        background: white;
        border-radius: 9000rem;
      }
    }
    @media screen and (max-width: 800px) {
      ul {
        padding: 0 1rem;
      }
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
      ul {
        margin: 1rem 0;
      }
    }
  }
</style>
