<script lang="ts">
  import type { Job } from '../data/experience'

  export let experience: Job[]
</script>

<div class="experience">
  {#each experience as job}
    <div class="job">
      <div class="job__title">{job.title}</div>
      <div class="job__company">
        {#if job.company_url}
          @
          <a class="link" href={job.company_url} target="_blank"
            >{job.company}</a
          >, {job.company_city}
        {:else}
          @ {job.company}, {job.company_city}
        {/if}
      </div>
      <div class="job__timespan">
        {job.year_start}
        {job.year_end ? `- ${job.year_end}` : '- now'}
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  @import '../style/global';
  .job {
    margin: 2rem 0;
    padding: 2rem 4rem;
    border: 1px solid $color_blue;
    text-align: left;

    &__title {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
  .link {
    color: $color_blue;
  }
</style>
