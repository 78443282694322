export const experience = [
    {
        title: 'Frontend Developer',
        company: 'Triple',
        company_city: 'Alkmaar',
        company_url: 'https://wearetriple.com',
        year_start: '2020',
        year_end: undefined,
    },
    {
        title: 'Frontend developer / teamlead',
        company: 'Touch Creative',
        company_city: 'Amsterdam',
        company_url: 'https://touchcreative.nl',
        year_start: '2013',
        year_end: '2020',
    },
    {
        title: 'Freelance Web Developer',
        company: 'Twicero',
        company_city: 'Amsterdam',
        year_start: '2009',
        year_end: '2014',
    },
    {
        title: 'Digital retoucher',
        company: 'Droomhuis360',
        company_city: 'Amsterdam',
        year_start: '2010',
        year_end: '2012',
    },
    {
        title: 'Webproducer',
        company: 'SPIL GAMES',
        company_city: 'Hilversum',
        year_start: '2007',
        year_end: '2009',
    },
    {
        title: 'Frontend Developer',
        company: 'Titans Sports Academy',
        company_city: 'Utrecht',
        year_start: '2007',
        year_end: '2007',
    },
    {
        title: 'Webdesigner',
        company: 'QNL - Qsite',
        company_city: 'Amsterdam',
        year_start: '2004',
        year_end: '2007',
    },
];
