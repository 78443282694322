<script lang="ts">
  import Button from './components/Button.svelte'
  import Greeting from './components/Greeting.svelte'
  import Bar from './components/Bar.svelte'
  import StackList from './components/StackList.svelte'
  import { stack } from './data/stack'
  import { experience } from './data/experience'
  import Experience from './components/Experience.svelte'
</script>

<main>
  <section class="intro">
    <Greeting />
    <div class="buttonbar">
      <Button
        type="external"
        text="Linkedin"
        href="https://www.linkedin.com/in/sjimwagemakers/"
      />
    </div>
  </section>
  <section class="more">
    <div class="introduction">
      <Bar />
      <div class="text">
        <p>
          Creative front-end developer since 2002 and able to combine this with
          video editing and motion design. While the main focus was always
          front-end development, Designing interfaces and user experience is an
          addition to what I can do on the frontend. Being both designer and
          developer allows me to make sure no detail is lost in translation.
        </p>
        <p>
          I work as front-end developer at <a
            target="_blank"
            href="https://wearetriple.com">Triple</a
          >
        </p>
      </div>
    </div>
  </section>
  <section class="experience">
    <div class="text">
      <h2>Work Experience</h2>
      <Experience {experience} />
    </div>
  </section>
  <section class="stack">
    <div class="text">
      <h2>Stack</h2>
      <StackList {stack} />
    </div>
  </section>
</main>

<style lang="scss">
  @import 'style/global';

  main {
    width: 100%;
    height: 100%;
  }

  section {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4rem 0;
  }

  section.intro {
    color: $color_blue;
    align-items: flex-start;
    flex-direction: column;
    height: 90vh;
    border-bottom: 5px solid $color_blue;
    background: $color_pink url('/sjim.png') no-repeat 100% 100%;
    background-size: contain;
    @media screen and (max-width: 1000px) {
      background-size: 25rem;
      background-position: 50% 100%;
      justify-content: flex-start;
    }
    @media screen and (max-width: 1000px) and (max-height: 650px) {
      background-image: none;
    }
  }
  section.more {
    background-color: $color_blue;
    color: $color_pink;
    padding: 4rem;
  }
  section.experience {
    background-color: $color_pink;
    color: $color_blue;
  }
  section.stack {
    background-color: $color_blue;
    padding: 4rem 0 6rem 0;
    h2 {
      color: $color_pink;
    }
  }

  .introduction {
    max-width: 60rem;
    font-size: 1.2rem;
  }

  .buttonbar {
    margin: 2rem 6rem 0;
  }
</style>
