<script lang="ts">
  import { fly, fade } from 'svelte/transition'
  import { quintOut } from 'svelte/easing'
</script>

<div
  class="bar"
  in:fly={{ delay: 500, duration: 1000, easing: quintOut, y: -200 }}
/>

<style lang="scss">
  @import '../style/global';
  .bar {
    background: $color_blue;
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0%;
    left: -2rem;
  }
</style>
