<script lang="ts">
  import { fade } from 'svelte/transition'
  import { quintOut } from 'svelte/easing'
  import Bar from './Bar.svelte'

  let animate = false
  setTimeout(() => {
    animate = !animate
  }, 100)
</script>

<div class="greeting">
  {#if animate}
    <Bar />
    <span class="top" in:fade={{ delay: 100, duration: 2000, easing: quintOut }}
      >Hi 👋🏻, i'm</span
    >
    <h1
      class="middle"
      in:fade={{ delay: 200, duration: 2000, easing: quintOut }}
    >
      Sjim Wagemakers
    </h1>
    <h2
      class="bottom"
      in:fade={{ delay: 500, duration: 2000, easing: quintOut }}
    >
      Creative front-end developer
    </h2>
  {/if}
</div>

<style lang="scss">
  .greeting {
    width: 70%;
    max-width: 600px;
    position: relative;
    text-align: left;
    margin-left: 6rem;

    .top {
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 600;
    }

    .middle {
      text-transform: uppercase;
      font-size: 3em;
      font-weight: 600;
    }

    .bottom {
      font-size: 2em;
      font-weight: 300;
    }
    @media screen and (max-width: 700px) {
      .middle {
        font-size: 2rem;
      }
      .bottom {
        font-size: 1rem;
      }
    }
  }
</style>
