<script lang="ts">
  export let type: 'external' | 'internal' = 'internal'
  export let href: string | undefined = undefined
  export let text: string = ''
</script>

<a target={type === 'external' ? '_blank' : '_self'} {href} class="button">
  {text}
</a>

<style lang="scss">
  @import '../style/global';

  .button {
    border: 2px solid $color_blue;
    display: inline-block;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0.5rem 0 0;
    background-color: $color_pink;
    color: $color_blue;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      background-color: $color_blue;
      color: $color_pink;
      cursor: pointer;
    }
  }
</style>
